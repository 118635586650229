<template>
  <div>
    <h2>智能控制系统</h2>
    <div class="product clearfix" v-for="product in products" :key="product.id">
      <img alt="#" :src='product.url'/>
      <div>
        <p class="title">{{product.title}}</p>
        <p v-for='(info,index) in product.infos' :key="index">{{info}}</p>
      </div>
    </div>
    <el-pagination background layout="prev, pager, next" :total="10">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data(){
    return {
      products:[
        {id:1,url:require('../../../assets/img/product4.png'),title:'A11',infos:['48V-BMS',
            'Max cells supportable by single board 15cells',
            'Max continuous charging current 20A（Configurable）',
            'Max continuous discharge current 40A（Configurable）',
            'Number of configurable temperature sensors 4',
            'Maximum balancing current 100mA',
            'Precision of Voltage ＜15mV',
            'Precision of Current 10mA',
            'Quiescent current ＜15mA',
            'Standby current ＜200uA',
            'Size 170*61.4*16mm']}]
    }
  }
};
</script>

<style scoped>
.el-pagination {
  width: 120px;
  margin: 20px auto;
}
p{
  width:80px;
  margin: 10px auto;
}

.product{
  margin: 10px auto;
  width: 90%;
  border-bottom:1px #E4E4E4 solid;
}
.product > div{
  width: 80%;
  float: left;
}
.product > img{
  float: left;
  width: 100px;
  height: 100px;
  margin: auto 20px ;
}
.title{
  color: #007BB9;
  font-weight: bold;
}
p{
  width:100%;
}
</style>