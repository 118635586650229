<template>
  <!-- 首页内容 -->
  <div>
    <!-- 轮播图 -->
    <div>
      <el-carousel type="card" class="card">
        <el-carousel-item v-for="item in imgs" :key="item.id">
          <img alt="#" class="loop-img" v-bind:src="item.url" @click="goto(item.clickUrl)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <p class="line"></p>
    <!-- 关于我们 -->
    <div class="about clearfix">
        <div>
            <h1>关于我们</h1><br/>
            <p>{{words}}</p><br/>
            <el-button type="warning" class="btn" @click="goto('/about/company')">更多</el-button>
        </div>
        <div>
            <img alt="公司图片" :src="require('../../assets/img/company.png')"/>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        { id: 0, url: require("../../assets/img/1.jpg"), clickUrl: "#" },
        { id: 1, url: require("../../assets/img/2.jpg"), clickUrl: "#" },
        { id: 2, url: require("../../assets/img/3.jpg"), clickUrl: "#" },
        { id: 3, url: require("../../assets/img/4.jpg"), clickUrl: "#" },
      ],
      words:`西安威兹德姆电子科技有限公司是一家以提供电器及智能控制系统为主的高科企业。
            自2020年成立以来，注册资金达1000万，公司注册有多项专利，以领先的智能控制系统，创造美好生活。
            公司拥有快速反应的资深研发团队，专业的研发能力，能根据客户的要求，在较短的时间内提供各种性能优越、符合认证要求的电器控制产品服务。
            公司拥有专业的智能控制公司拥有快速反应的资深研发团队，专业的研发能力。
            能根据客户的要求，在较短的时间内提供各种性能优越、符合认证要求的电器控制产品服务。`,
    };
  },
  methods: {
    goto(path) {
      console.log(path);
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
.card {
  height: 360px;
  width: 1180px;
  margin: 10px auto;
}

.card > .el-carousel__container {
  height: 90% !important;
}

.loop-img{
    width: 600px;
    height: 287px;
}

.line{
    width: 100%;
    height: 2px;
    background-color: #DDDDDD;
}
.about > div{
    margin-top: 20px;
    margin-bottom:20px ;
    margin-right:30px ;
    float: left;
}
.about >div:first-child{
    width: 700px;
    margin-left: 200px;
}
.btn{
    width: 200px;
    margin-top: 10px;
    background-color: #EF8716!important;
}

.link{
    background: #F6F6F6;
    width: 90%;
    margin-left: 5%;
    height: 30px;
    padding-top: 16px;
    border-bottom:1px #E4E4E4 solid;
}
.link>a{
    text-decoration: none;
    color: #888888;
}
</style>