<template>
  <div>
    <!-- 头部 -->
    <div class="index-top clearfix">
      <div>
        <a href="#">
          <img :src="require('../../assets/img/logo.png')" style="max-width: 35%; height: auto; margin-top: 16px"/>
        </a>
      </div>
      <div style="margin-top: 20px">
<!--        <span>联系方式：support@wisdomelectronic.com</span>-->
        <el-input placeholder="搜索..." class="input" v-model="input">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search()"></i>
        </el-input>
      </div>
    </div>

    <!-- 导航条 -->
    <div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu"
        mode="horizontal"
        :router="true"
        background-color="#013044"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-menu-item index="/index">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">关于我们</template>
          <el-menu-item index="/about/company">公司简介</el-menu-item>
          <el-menu-item index="/about/research">研发能力</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">产品</template>
          <el-menu-item index="/products/product1">电机及其控制系统</el-menu-item>
          <el-menu-item index="/products/product2">配电开关控制设备</el-menu-item>
          <el-menu-item index="/products/product3">智能控制系统</el-menu-item>
          <el-menu-item index="/products/product4">计算机软硬件及辅助设备</el-menu-item>
        </el-submenu>
        <el-menu-item index="/service">服务承诺</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
      </el-menu>
    </div>

    <!-- 指定展示的位置 -->
    <div>
      <router-view></router-view>
    </div>

    <!-- 底部 -->
    <div class="index-bottom">
      <div class="clearfix info">
        <div>
          <h2>产品目录</h2>
          <div v-for="content in contents" :key="content.id">
            <i class="el-icon-location-outline"></i>
            <router-link class="link" :to="content.url">{{
              content.name
            }}</router-link>
          </div>
        </div>
        <div>
          <h2>快速导航</h2>
          <div v-for="nav in navs" :key="nav.id">
            <i class="el-icon-location-outline"></i>
            <router-link class="link" :to="nav.url">{{ nav.name }}</router-link>
          </div>
        </div>
        <div>
          <p>
            西安威兹德姆电子科技有限公司<br /><br />
            地址:陕西省西安市曲江新区雁塔南路885号金辉环球广场<br /><br />
            E座4幢1单元11539号<br /><br />
            联系方式：support@wisdomelectronic.com<br /><br />
            <!--            电子邮件:support@lthsmetech.com<br /><br />-->
          </p>
        </div>
      </div>
        <!--备案号居中-->
        <div style="display: inline-block;width: 100%;text-align: center;margin-top: 20px">
          <p>Copyright © 西安威兹德姆电子科技有限公司. All Rights Reserved. Sitemap</p>
        </div>
        <div style="display: inline-block;width:100%;text-align: center;margin-bottom: 10px;margin-top: 10px">
          <p>
            <a href="https://beian.miit.gov.cn" style="color: #606266;text-decoration: none">陕ICP备2022005809号</a>
            <a >&nbsp;陕公网安备61011302001459号</a>
          </p>
        </div>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      input: "",
      activeIndex: "/index",
      contents: [
        { id: 0, name: "电机及其控制系统研发", url: "/products/product1" },
        { id: 1, name: "配电开关控制设备研发", url: "/products/product2" },
        { id: 2, name: "智能控制系统集成", url: "/products/product3" },
        { id: 3, name: "计算机软硬件及辅助设备", url: "/products/product4" },
      ],
      navs: [
        { id: 0, name: "主页", url: "/index" },
        { id: 1, name: "关于我们", url: "/about/company" },
        // { id: 2, name: "产品", url: "/products/product1" },
        //{ id: 2, name: "人力资源", url: "/resource/person" },
        // { id: 4, name: "新闻", url: "/news/new" },
        { id: 2, name: "服务承诺", url: "/service" },
        { id: 3, name: "联系我们", url: "/contact" },
      ],
    };
  },
  methods: {
    search() {
      if (this.input !== "") {
        this.input = "";
        this.$router.push({
          path: "/products/product1",
        });
      }else{
        alert("输入为空!");
      }
    },
  },
};
</script>

<style scoped>
.index-top > div:first-child {
  margin-left: 100px;
  float: left;
  width: 40%;
}

.index-top > div:last-child {
  margin-top: 40px;
  margin-right: 10%;
  float: right;
}

.input {
  float: right;
  width: 200px;
}

span {
  display: inline-block;
  margin-top: 8px;
  margin-right: 200px;
  font-weight: bold;
}

.el-menu-item.is-active,
.el-menu-item:hover {
  background-color: #002537 !important;
}
i {
  color: orange;
  font-size: 18px;
}
.el-menu {
  padding-left: 15%;
}
.el-menu-item,
.el-submenu /deep/ .el-submenu__title {
  font-size: 16px;
  padding-left: 32px;
  padding-right: 32px;
}

.index-bottom {
  background-color: #002d40;
}

.info > div {
  float: left;
  margin-left: 200px;
  margin-top: 20px;
}
.info > div > div {
  margin-top: 10px;
}
p,
h2,
.link,
.info > div > div > i {
  color: #fff;
  font-size: 14px;
}
.link {
  text-decoration: none;
}
.link:hover {
  color: royalblue;
}
.index-bottom > p {
  margin-top: 50px;
  margin-left: 220px;
}
</style>
