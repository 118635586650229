<template>
    <!-- 关于我们页面 -->
    <div>
        <div class="img">
            <img alt="#" :src="require('../../assets/img/background.png')"/>
        </div>
        <div class="container clearfix">
            <p class="line"></p>
            <!-- 关于我们页面菜单栏 -->
            <div>
                <div v-for="choice in choices" :key="choice.id" class="menu">
                    <router-link class="btn" active-class="active" :to='choice.url'>{{choice.name}}</router-link>
                </div>
            </div>
            <!-- 关于我们页面内容 -->
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            choices:[
                {id:0,name:'公司简介',url:'/about/company'},
                {id:1,name:'研发能力',url:'/about/research'},
            ]
        }
    }
}
</script>

<style scoped>
img{
    width: 100%;
}

.container{
    width: 70%;
    margin: 10px auto;
}

.container > div{
    float: left;
}

.container>div:last-child{
    width: 800px;
    margin-left: 30px;
}

.menu{
    height: 40px;
    width: 200px;
    border-bottom: 1px #DCDCDC solid;
}

.btn {
  display: block;
  height: 40px;
  width: 200px;
  background-color: #ffffff;
  color: #333333;
  text-align:center;
  line-height:40px;
  text-decoration: none;
}

.btn:hover {
    color:#FFFFFF;
    background-color: #61666d;
}

.active {
  color:#FFFFFF;
  background-color: #00aeec;
}

.line{
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background-color: #DDDDDD;
}

.img{
    width: 100%;
}

img{
    margin:3px 1%;
    width: 98%;
    height: 300px;
}
</style>