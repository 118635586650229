<template>
    <!-- 服务承诺页面 -->
    <div>
        <div>
            <img alt="#" :src="require('../../assets/img/service.png')"/>
        </div>
        <div class="container clearfix">
            <p class="line"></p>
            <!-- 服务承诺页面菜单栏 -->
            <div>
                <div v-for="choice in choices" :key="choice.id" class="menu">
                    <router-link class="btn" active-class="active" :to='choice.url'>{{choice.name}}</router-link>
                </div>
            </div>
            <!-- 服务承诺页面内容 -->
            <div class="content">
                <div v-for="(word,index) in words" :key="index">
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{word}}</p>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            choices:[
                {id:0,name:'联系我们',url:'/contact'},
                {id:1,name:'关于我们',url:'/about/company'},
                {id:2,name:'产品',url:'/products/product1'},
                {id:3,name:'服务承诺',url:'/service'}
            ],
            words:[
                `为进一步提高产品质量，提高西安威兹德姆电子科技有限公司产品在国际、国内市场的地位和在用户中的信誉度，真正实现“把方便让给顾客，把困难留给自己”的服务宗旨。我们郑重向社会各界、广大用户发表如下声明：`,
                `一、加强售前、售中、售后服务，把24小时服务、超前服务、全程服务、终身服务、贯彻在产品制造、安装、调试、大修的全过程；保证出厂产品达到相应标准或合同规定的技术条件，并使出厂产品合格率达到 100% 。`,
                `二、为重点工程配套的设备和产品 , 保证按合同要求：保质保量、及时准确交货，对需要技术服务的将派专业技术服务人员参加开箱验收和指导安装、调试工作，直至设备正常运转。`,
                `三、接到用户要求解决产品在使用中出现的问题的通知后，公司当地销售公司或当地办事在12小时内赶到现场进行服务；公司4小时内作出答复；若需现场解决，公司保证24小时内派出技术服务人员，并做到质量不解决，服务人员不撤离。`,
                `四、保证兑现产品“ 三包 ”服务规定，产品在“ 三包 ”服务期内确属产品质量问题，严格履行合同中规定的义务。凡超“ 三包 ”期限的产品，若用户需要维修服务，保证提供维修服务。`,
                `五、若用户需进行技术培训，本公司会全力帮助用户培训操作、保养、维修等有关人员，并对重点用户进行走访，质量跟踪。`,
                `六、定期召开用户座谈会，并对用户来函、来电、来访等信息，及时做好登传递、分析、处理、反馈等工作，并根据用户需求改进产品性能，不断提高产品质量。`,
                `七、随时满足需方对备品、备件的要求。`,
                `八、无论在何种情况下，我方决不以任何理由刁难需方。本承诺函将成为合同不可分割的部分，与合同具有同等效力。`
            ]
        }
    }
}
</script>

<style scoped>
img{
    width: 100%;
}

.container{
    width: 70%;
    margin: 10px auto;
}

.container > div{
    float: left;
}

.container>div:last-child{
    width: 800px;
    margin-left: 30px;
}

.menu{
    height: 40px;
    width: 200px;
    border-bottom: 1px #DCDCDC solid;
}

.btn {
  display: block;
  height: 40px;
  width: 200px;
  background-color: #ffffff;
  color: #333333;
  text-align:center;
  line-height:40px;
  text-decoration: none;
}

.btn:hover {
    color:#FFFFFF;
    background-color: #61666d;
}

.active {
  color:#FFFFFF;
  background-color: #00aeec;
}

.line{
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background-color: #DDDDDD;
}
</style>