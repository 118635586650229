<template>
  <!-- 公司介绍页面 -->
  <div>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{words[0]}}</p>
      <br/>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{words[1]}}</p>
      <br/>
      <img alt="#" :src="require('../../../assets/img/research2.png')"/>
  </div>
</template>

<script>
export default {
    data(){
        return {
            words:[
                `西安威兹德姆电子科技有限公司是一家以提供电器及智能控制系统为主的高科企业，
            自2020年成立以来，注册资金达1000万，公司注册有多项专利，以领先的智能控制系统，创造美好生活，
            公司拥有快速反应的资深研发团队，专业的研发能力，能根据客户的要求，在较短的时间内提供各种性能优越、符合认证要求的电器控制产品服务。
            公司拥有专业的智能控制公司拥有快速反应的资深研发团队，专业的研发能力。
            能根据客户的要求，在较短的时间内提供各种性能优越、符合认证要求的电器控制产品服务。`,
            `我们以“全面创新、不断超越客户期望”为服务宗旨，以高可靠、高品质、高效率和极具竞争力的价格及完善的售后服务，赢得了广大顾客的信任和赞誉。
            我们以“全面创新、不断超越客户期望”为服务宗旨，以高可靠、高品质、高效率和极具竞争力的价格及完善的售后服务，赢得了广大顾客的信任和赞誉.`
            ]
        }
    }
}
</script>

<style>

</style>