<template>
  <div>
    <h2>电机及其控制系统</h2>
    <div class="product clearfix" v-for="product in products" :key="product.id">
        <img alt="#" :src='product.url'/>
        <div>
            <p class="title">{{product.title}}</p>
            <p v-for='(info,index) in product.infos' :key="index">{{info}}</p>
        </div>
    </div>
    <el-pagination background layout="prev, pager, next" :total="10">
    </el-pagination>
  </div>
</template>

<script>
export default {
    data(){
        return {
            products:[
                {id:0,url:require('../../../assets/img/product1.png'),title:'M100 BBTR',infos:['Position   Center','Rated Power 250W~500W',
                  'Efficiency ≥80%',
                  'Max.Torque 100Nm/120Nm',
                  'Weight ≤4.3kg']},
                {id:1,url:require('../../../assets/img/product2.png'),title:'M80 BBTR',infos:['Position Center','Rated Power 250W/350W',
                  'Efficiency ≥80%',
                  'Max.Torque 80Nm',
                  'Weight ≤3.7kg']},
                {id:2,url:require('../../../assets/img/product3.png'),title:'M145 CD',infos:['Position Rear','Rated Power 	250W~500W',
                    'Efficiency 	≥80%',
                    'Max.Torque 	50Nm/60Nm',
                    'Weight 	≤3.4kg,']},
            ]
        }
    }
};
</script>

<style scoped>
.el-pagination {
  width: 120px;
  margin: 20px auto;
}
p{
    width:80px;
    margin: 10px auto;
}

.product{
    margin: 10px auto;
    width: 90%;
    border-bottom:1px #E4E4E4 solid;
}
.product > div{
    width: 80%;
    float: left;
}
.product > img{
    float: left;
    width: 100px;
    height: 100px;
    margin: auto 20px ;
}
.title{
    color: #007BB9;
    font-weight: bold;
}
p{
    width:100%;
}
</style>