<template>
    <!-- 产品页面 -->
    <div>
        <div>
            <img alt="#" :src="require('../../assets/img/contact.png')"/>
        </div>
        <div class="container clearfix">
            <p class="line"></p>
            <!-- 产品页面菜单栏 -->
            <div>
                <div v-for="choice in choices" :key="choice.id" class="menu">
                    <router-link class="btn" active-class="active" :to='choice.url'>{{choice.name}}</router-link>
                </div>
            </div>
            <!-- 产品页面内容 -->
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            choices:[
                { id: 0, name: "电机及其控制系统", url: "/products/product1" },
                { id: 1, name: "配电开关控制设备", url: "/products/product2" },
                { id: 2, name: "智能控制系统", url: "/products/product3" },
                { id: 3, name: "计算机软硬件及辅助设备", url: "/products/product4" }
            ]
        }
    }
}
</script>

<style scoped>
img{
    width: 100%;
}

.container{
    width: 70%;
    margin: 10px auto;
    height: 760px;
    overflow: auto;
}

.container > div{
    float: left;
}

.container>div:last-child{
    width: 800px;
    margin-left: 30px;
}

.menu{
    height: 40px;
    width: 200px;
    border-bottom: 1px #DCDCDC solid;
}

.btn {
  display: block;
  height: 40px;
  width: 200px;
  background-color: #ffffff;
  color: #333333;
  text-align:center;
  line-height:40px;
  text-decoration: none;
}

.btn:hover {
    color:#FFFFFF;
    background-color: #61666d;
}

.active {
  color:#FFFFFF;
  background-color: #00aeec;
}

.line{
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background-color: #DDDDDD;
}
</style>