<template>
    <!-- 联系我们页面 -->
    <div>
        <div>
            <img alt="#" :src="require('../../assets/img/contact.png')"/>
        </div>
        <div class="container clearfix">
            <p class="line"></p>
            <!-- 联系我们页面菜单栏 -->
            <div>
                <div v-for="choice in choices" :key="choice.id" class="menu">
                    <router-link class="btn" active-class="active" :to='choice.url'>{{choice.name}}</router-link>
                </div>
            </div>
            <!-- 联系我们页面内容 -->
            <div class="content">
                <div v-for="(word,index) in words" :key="index">
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{word}}</p>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            choices:[
                {id:0,name:'联系我们',url:'/contact'},
                {id:1,name:'关于我们',url:'/about/company'},
                {id:2,name:'产品',url:'/products/product1'},
                {id:3,name:'服务承诺',url:'/service'}
            ],
            words:[
                `西安威兹德姆电子科技有限公司`,
                `地址:陕西省西安市曲江新区雁塔南路885号金辉环球广场E座4幢1单元11539号`,
                `联系方式：support@wisdomelectronic.com`,
                // `网址:www.lthsmetech.com`,
                // `人力资源部`,
                // `邮箱:brant.lee@lthsmetech.com`,
                // `市场部`,
                // `邮箱:support@lthsmetech.com`
            ]
        }
    }
}
</script>

<style scoped>
img{
    width: 100%;
}

.container{
    width: 70%;
    margin: 10px auto;
    height: 360px;
    overflow: auto;
}

.container > div{
    float: left;
}

.container>div:last-child{
    width: 800px;
    margin-left: 30px;
}

.menu{
    height: 40px;
    width: 200px;
    border-bottom: 1px #DCDCDC solid;
}

.btn {
  display: block;
  height: 40px;
  width: 200px;
  background-color: #ffffff;
  color: #333333;
  text-align:center;
  line-height:40px;
  text-decoration: none;
}

.btn:hover {
    color:#FFFFFF;
    background-color: #61666d;
}

.active {
  color:#FFFFFF;
  background-color: #00aeec;
}

.line{
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background-color: #DDDDDD;
}
</style>
