<template>
    <!-- 研发能力页面 -->
    <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{words[0]}}</p>
        <br/>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{words[1]}}</p>
        <br/>
        <img alt="#" :src="require('../../../assets/img/research1.png')"/>
        <img alt="#" :src="require('../../../assets/img/research3.png')"/>
    </div>
</template>

<script>
export default {
    data(){
        return{
            words:[
                `公司注重科研开发，成立至今每年投入大量资金，与国内先进科研机构长期合作，短期内拥有多个型号的产品专利及各种质量认证;
                公司注重品质管理，每个方案无不经过相关部门反复优化筛选，严密测试，以确保每一款产品的安全、稳定;`,
                `公司注重技术创新，产品的灵敏度、稳定性得到了质的飞跃，以及有线探测器带线插拔式安装方式使其安装简单方便，得到了众多工程商的认可;
                公司更注重优质服务，时刻关注客户的需求，一切从客户需求出发，以客户满意结束!`
                ]
        }
    }
}
</script>

<style>

</style>