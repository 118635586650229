<template>
  <div>
    <h2>计算机软硬件及其辅助设备</h2>
    <div class="product clearfix" v-for="product in products" :key="product.id">
      <img alt="#" :src='product.url'/>
      <div>
        <p class="title">{{product.title}}</p>
        <p v-for='(info,index) in product.infos' :key="index">{{info}}</p>
      </div>
    </div>
    <el-pagination background layout="prev, pager, next" :total="10">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data(){
    return {
      products:[
        {id:1,url:require('../../../assets/img/product5.png'),title:'HP 136W',infos:[
            '端口  USB；WiFi端口 ',
            '扩展功能 支持无线网络打印',
            '支持纸张尺寸  A5；A4',
            '能效等级 二级能效',
            '产品净重  7.46kg',
            '黑白模式最佳打印分辨率  1200*1200dpi']}]
    }
  }
};
</script>

<style scoped>
.el-pagination {
  width: 120px;
  margin: 20px auto;
}
p{
  width:80px;
  margin: 10px auto;
}

.product{
  margin: 10px auto;
  width: 90%;
  border-bottom:1px #E4E4E4 solid;
}
.product > div{
  width: 80%;
  float: left;
}
.product > img{
  float: left;
  width: 100px;
  height: 100px;
  margin: auto 20px ;
}
.title{
  color: #007BB9;
  font-weight: bold;
}
p{
  width:100%;
}
</style>