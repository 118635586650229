import { render, staticRenderFns } from "./Product2.vue?vue&type=template&id=7497f626&scoped=true&"
import script from "./Product2.vue?vue&type=script&lang=js&"
export * from "./Product2.vue?vue&type=script&lang=js&"
import style0 from "./Product2.vue?vue&type=style&index=0&id=7497f626&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_5a7zypegj5b2h26m45lhzwpe6a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7497f626",
  null
  
)

export default component.exports